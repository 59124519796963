.banner {
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    filter: grayscale(100%);
}
